import {useState, useEffect, useMemo} from 'react';

export const getWindowDimensions = () => {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    height,
    width
  };
};

export const useScreenSizes = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const {width} = getWindowDimensions();

  const isDesktop = useMemo(() => width > 1024, [width]);
  // const isSmallDesktop = useMemo(() => width < 1300 && width > 1024, [width]);
  // const isMediumDesktop = useMemo(() => width < 1300 && width > 1024, [width]);
  // const isBigDeskt:op = useMemo(() => width < 1300 && width > 1024, [width]);
  const isTablet = useMemo(() =>  width >= 768 && width < 1024, [width]);
  const isMobile = useMemo(() =>  width < 768, [width]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isDesktop,
    isMobile,
    isResponsiveMode: isMobile || isTablet,
    isTablet,
    windowDimensions
  };
};
