import {palette} from './palette';

export const colors = {
  background: {
    blue: palette.blue.dark,
    orange: palette.orange.light
  },
  basics: {
    black: palette.black.default,
    transparent: palette.transparent,
    white: palette.white.default
  },
  borders: {
    default: palette.gray.light,
    primary: palette.green.light,
    secondary: palette.blue.medium
  },
  buttons: {
    blue: palette.blue.medium,
    default: palette.green.light,
    disabled: palette.gray.light,
    waiting: palette.yellow.light
  },
  checkBox: {
    checked: palette.blue.light,
    default: palette.blue.transparent
  },
  inputs: {
    disabled: palette.gray.light
  },
  operatorStatus: {
    archived: palette.red.transparent,
    available: palette.green.transparent
  },
  shadow: {
    default: palette.black.transparent
  },
  text: {
    blue: palette.blue.dark,
    default: palette.black.default,
    disabled: palette.gray.dark,
    error: palette.red.light,
    green: palette.green.light,
    orange: palette.orange.light,
    yellow: palette.yellow.light

  },
  tickets: {
    closed: palette.green.transparent,
    opened: palette.red.transparent,
    waiting: palette.yellow.transparent
  }
};
