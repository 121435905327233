import React from 'react';

import {Characters, MangaImage, WebtoonImages} from '@assets';
import {getWindowDimensions, useScreenSizes} from '@modules';

import {Props} from './interfaces';
import {DesktopBackground, MobileBackground} from './Templates';
import {useStyles} from './useStyles';

export const Background: React.FC<Props> = () => {
  const {isResponsiveMode} = useScreenSizes();
  const {width} = getWindowDimensions();
  const styles = useStyles();
  return isResponsiveMode
    ? <MobileBackground />
    : <DesktopBackground />;
};
