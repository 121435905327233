import {useMemo} from 'react';

import {colors} from './colors';
import {fonts} from './fonts';
import {metrics} from './metrics';

export interface ThemeType {
  colors: typeof colors
  fonts: typeof fonts
  metrics: typeof metrics
}

export const useTheme = () => {
  const theme: ThemeType = useMemo(() => ({
    colors,
    fonts,
    metrics
  }), []);
  return theme;
};
