export const palette = {
  black: {
    default: '#000000',
    transparent: 'rgba(0, 0, 0, 0.25)'
  },
  blue: {
    dark: '#1F2547',
    light: '#1423DC',
    medium: '#2382D2',
    transparent: 'rgba(30, 30, 45, 0.95)'
  },
  gray: {
    dark: '#666666',
    light: '#B3B3B3'
  },
  green: {
    light: '#96CD32',
    transparent: 'rgba(150,205,50,0.25)'
  },
  orange: {
    light: '#FF9922',
    transparent: 'rgba(255,153,35,0.95)'
  },
  red: {
    light: '#D34848',
    transparent: 'rgba(197, 11, 11, 0.25)'
  },
  transparent: 'transparent',
  white: {
    default: '#FFFFFF',
    transparent: 'rgba(255, 255, 255, 0.2)'
  },
  yellow: {
    light: '#FFC328',
    transparent: 'rgba(255,195,40, 0.25)'
  }
};
