import React from 'react';

import {Characters, MangaImage, WebtoonImages} from '@assets';
import {Title} from '@components';
import {getWindowDimensions, useScreenSizes} from '@modules';
import {TextVariantsEnum} from '@sharedInterfaces';

import {Props} from './interfaces';
import {useStyles} from './useStyles';

export const MobileBackground: React.FC<Props> = () => {
  const {isDesktop, isTablet, isMobile} = useScreenSizes();
  const {width} = getWindowDimensions();
  const titleVariant = isMobile ? TextVariantsEnum.H5 : TextVariantsEnum.H4;
  const DefenderOfTheAfterLifeSize = isMobile ? 200 : 250;
  const styles = useStyles();
  return (
    <div style={{
      bottom: 0,
      display: 'flex',
      flex: 1,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0
    }}>
      <div style={styles.container}>
        <div style={styles.leftContainer}>
          <div
            style={{
              animation: 'InfiniteMangaImage 1000s linear infinite',
              backgroundImage: `url("${MangaImage}")`,
              backgroundRepeat: 'repeat',
              backgroundSize: 'cover',
              height: '100%',
              left: 0,
              position: 'absolute',
              right: 0,
              top: 0,
              width: '100%'
            }} />
          <div style={styles.orangeBox}>
            {!isMobile && (

              <img
                src={Characters.AfroDeath}
                style={{
                  height: 230,
                  width: 230
                }} />
            )}
            <img
              src={Characters.DefenderOfTheAfterLife}
              style={{
                height: DefenderOfTheAfterLifeSize,
                width: DefenderOfTheAfterLifeSize
              }} />
            {!isMobile && (
              <img
                src={Characters.JeokYeob}
                style={{
                  height: 200,
                  width: 200
                }} />
            )}
          </div>

        </div>
        <div style={styles.middleLine} />
        <div style={styles.rightContainer}>
          <div
            style={{
              animation: 'InfiniteWebtoonCategoriesImage 1000s linear infinite',
              backgroundImage: `url("${WebtoonImages}")`,
              backgroundRepeat: 'repeat',
              backgroundSize: 'cover',
              height: '100%',
              left: 0,
              position: 'absolute',
              right: 0,
              top: 0,
              width: '100%'
            }} />
          <div style={styles.blueBox}>
            <Title
              style={styles.title}
              variant={titleVariant}
            >A new way to Read</Title>
          </div>
        </div>
      </div>
    </div>
  );
};
