import React, {useMemo} from 'react';

import {useTheme} from '@theme';

export const useStyles = () => {
  const theme = useTheme();
  const styles = useMemo(() => ({
    afroDeathStyle: {
      height: 300,
      width: 300
    },
    blueBox: {
      animation: 'TransparentTransition 3s linear forwards',
      backgroundColor: theme.colors.background.blue,
      bottom: 0,
      left: 0,
      position: 'absolute' as 'absolute',
      right: 0,
      top: 0
    },
    container: {
      alignItems: 'center',
      bottom: 0,
      display: 'flex',
      flex: 1,
      flexDirection: 'row' as 'row',
      justifyContent: 'center',
      left: 0,
      position: 'fixed' as 'fixed',
      right: 0,
      top: 0,
      transform: 'rotate(-10deg) scale(2)',
      width: '100%',
      zIndex: -1
    },
    defenderOfTheAfterLifeStyle: {
      height: 300,
      width: 300
    },
    jeokYeob: {
      height: 250,
      width: 250
    },
    joCheonWoo: {
      height: 276,
      width: 190
    },
    leftContainer: {
      height: '100%',
      position: 'relative' as 'relative',
      width: 'calc(50% - 4px)'
    },
    middleLine: {
      backgroundColor: theme.colors.basics.white,
      boxShadow: 'rgb(0 0 0 / 20%) 0px 0px 6px 1px',
      height: '100%',
      width: 8,
      zIndex: 1
    },
    orangeBox: {
      animation: 'TransparentTransition 3s linear forwards',
      backgroundColor: theme.colors.background.orange,
      bottom: 0,
      left: 0,
      position: 'absolute' as 'absolute',
      right: 0,
      top: 0
    },
    rightContainer: {
      height: '100%',
      position: 'relative' as 'relative',
      width: 'calc(50% - 4px)'
    }
  }), [theme]);
  return styles;
};
