import AfroDeath from './afro_death.png';
import Background from './Background.png';
import BusinessMan from './BusinessMan.png';
import BusinessManRectangle from './BusinessManRectangle.png';
import DefenderOfTheAfterLife from './DefendersOfTheAfterlife.png';
import JeokYeob from './Jeok-Yeob.png';
import JoCheonWoo from './jo-cheon-woo.png';
import MangaImage from './MangaPage.png';
import WebtoonImages from './PublisherBG.png';
import WritingAnimeGirl from './WritingAnimeGirl.png';

const Characters = {
  AfroDeath,
  BusinessMan,
  BusinessManRectangle,
  DefenderOfTheAfterLife,
  JeokYeob,
  JoCheonWoo,
  WritingAnimeGirl
};

export {
  Background,
  WebtoonImages,
  MangaImage,
  Characters
};
