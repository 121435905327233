import React, {useMemo} from 'react';

import {ThemeType} from '@theme';

export const useStyles = (theme: ThemeType) => {
  const styles = useMemo(() => ({
    belowLogoContent: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 16,
      width: '100%'
    },
    container: {
      height: '100%'
    },
    contentContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column' as 'column',
      height: '100%',
      justifyContent: 'center'
    },
    halfDiv: {
      flex: 0.4
    },
    leftTitle: {
      color: theme.colors.text.blue,
      marginRight: theme.metrics.spacings.m,
      textAlign: 'right'
    },
    publishTitle: {
      color: theme.colors.text.orange,
      marginBottom: theme.metrics.spacings.m,
      marginLeft: theme.metrics.spacings.l,
      marginTop: theme.metrics.spacings.m
    },
    readTitle: {
      color: theme.colors.text.orange,
      marginBottom: theme.metrics.spacings.m,
      marginLeft: theme.metrics.spacings.m
    },
    supportTitle: {
      color: theme.colors.text.orange,
      marginBottom: theme.metrics.spacings.m,
      marginLeft: theme.metrics.spacings.xl,
      marginTop: theme.metrics.spacings.m
    }
  }), [theme]);
  return styles;
};
