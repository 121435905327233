import React, {useMemo} from 'react';

import {useScreenSizes} from '@modules';
import {useTheme} from '@theme';

export const useStyles = () => {
  const theme = useTheme();
  const {isResponsiveMode} = useScreenSizes();
  const styles = useMemo(() => ({
    container: {
      backgroundColor: theme.colors.basics.white,
      borderRadius: theme.metrics.borderRadius.default,
      cursor: 'default',
      display: 'flex',
      flexDirection: 'row' as 'row',
      padding: theme.metrics.spacings.m,
      width: 'fit-content',
      ...(isResponsiveMode && {
        // bottom: 0,
        // left: 0,
        // position: 'absolute' as 'absolute',
        // right: 0,
        // top: 0
      })
    },
    containerInAnimation: {
      animation: 'CryptoonLogoIn .8s ease-in-out forwards'
    },

    containerOutAnimation: {
      animation: 'CryptoonLogoOut .8s ease-in-out forwards'
    },
    cryp: {
      color: theme.colors.text.blue,
      marginBottom: 0,
      marginRight: theme.metrics.spacings.xm,
      marginTop: 0,
      userSelect: 'none'
    },
    toon: {
      color: theme.colors.text.orange,
      marginBottom: 0,
      marginTop: 0,
      userSelect: 'none'
    }
  }), [theme]);
  return styles;
};
