import React from 'react';

import {Characters, MangaImage, WebtoonImages} from '@assets';
import {getWindowDimensions, useScreenSizes} from '@modules';

import {Props} from './interfaces';
import {useStyles} from './useStyles';

export const DesktopBackground: React.FC<Props> = () => {
  const {isDesktop} = useScreenSizes();
  const {width} = getWindowDimensions();
  const styles = useStyles();
  return (
    <div style={{
      bottom: 0,
      display: 'flex',
      flex: 1,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0
    }}>
      <div
        style={{
          display: 'flex',
          flex: 0.5,
          position: 'relative',
          userSelect: 'none'
        }}>
        {isDesktop && (
          <div
            className="BottomAnimation"
            style={{
              bottom: 0,
              left: 0,
              lineHeight: 0,
              position: 'absolute'
            }}
          >
            {width > 1300 && (
              <img
                src={Characters.AfroDeath}
                style={styles.afroDeathStyle}
              />
            )}
            <img
              src={Characters.DefenderOfTheAfterLife}
              style={styles.defenderOfTheAfterLifeStyle}
            />
            {width > 1800 && (
              <img
                src={Characters.JeokYeob}
                style={styles.jeokYeob}
              />
            )}
            {width > 2100 && (
              <img
                src={Characters.JoCheonWoo}
                style={styles.joCheonWoo}
              />
            )}
          </div>
        )}
      </div>
      <div style={{
        display: 'flex',
        flex: 0.5,
        position: 'relative',
        userSelect: 'none'
      }}>
        {isDesktop && (
          <div
            className="BottomAnimation"
            style={{
              bottom: 0,
              lineHeight: 0,
              position: 'absolute',
              right: 0
            }}>
            <img
              src={Characters.BusinessManRectangle}
              style={{
                height: 300,
                left: 16,
                position: 'relative',
                width: 175
              }}
            />
            {width > 1300 && (
              <img
                src={Characters.WritingAnimeGirl}
                style={{
                  height: 300,
                  position: 'relative',
                  width: 300
                }}
              />
            )}
          </div>
        )}
      </div>
      <div style={styles.container}>
        <div style={styles.leftContainer}>
          <div
            style={{
              animation: 'InfiniteMangaImage 1000s linear infinite',
              backgroundImage: `url("${MangaImage}")`,
              backgroundRepeat: 'repeat',
              backgroundSize: 'contain',
              height: '100%',
              position: 'absolute',
              right: 0,
              top: 0,
              width: '100%'
            }} />
          <div style={styles.orangeBox} />
        </div>
        <div style={styles.middleLine} />
        <div style={styles.rightContainer}>
          <div
            style={{
              animation: 'InfiniteWebtoonCategoriesImage 1000s linear infinite',
              backgroundImage: `url("${WebtoonImages}")`,
              backgroundRepeat: 'repeat',
              backgroundSize: 'contain',
              height: '100%',
              position: 'absolute',
              right: 0,
              top: 0,
              width: '100%'
            }} />
          <div style={styles.blueBox} />
        </div>
      </div>
    </div>
  );
};
