import React, {useMemo} from 'react';

import {useScreenSizes} from '@modules';
import {TextVariantsEnum} from '@sharedInterfaces';
import {useTheme} from '@theme';

export const useStyles = () => {
  const theme = useTheme();
  const {isResponsiveMode} = useScreenSizes();
  const defaultStyles = useMemo(() => ({
    title: {
      fontFamily: theme.fonts.FontFamily.PRIMARY
    }
  }), [theme]);

  const variantStyles: Record<TextVariantsEnum, Object> = useMemo(() => ({
    [TextVariantsEnum.H1]: {
      fontSize: isResponsiveMode ? theme.fonts.fontSizes.h : theme.fonts.fontSizes.xxl
    },
    [TextVariantsEnum.H2]: {
      fontSize: isResponsiveMode ? theme.fonts.fontSizes.xxl : theme.fonts.fontSizes.xl
    },
    [TextVariantsEnum.H3]: {
      fontSize: isResponsiveMode ? theme.fonts.fontSizes.xl : theme.fonts.fontSizes.l
    },
    [TextVariantsEnum.H4]: {
      fontSize: isResponsiveMode ? theme.fonts.fontSizes.l : theme.fonts.fontSizes.xm
    },
    [TextVariantsEnum.H5]: {
      fontSize: isResponsiveMode ? theme.fonts.fontSizes.xm : theme.fonts.fontSizes.m
    }
  }), [theme]);
  return {
    defaultStyles,
    variantStyles
  };
};
