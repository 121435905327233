// SHARED ENUMS FOR COMPONENTS USAGE

export enum TextVariantsEnum {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5'
}

export enum ButtonVariantsEnum {
  TEXT = 'TEXT',
  OUTLINED = 'OUTLINED',
  DEFAULT = 'DEFAULT',
  BLUE = 'BLUE',
  DISABLED = 'DISABLED',
}

export enum CustomCardWidthEnum {
  DEFAULT = 500,
  FULL_SIZE_CONTAINER_WIDTH = 650,
  FULL_SIZE = 1280
}

export enum SpecificHeightsEnum {
  HEADER = 55,
  HEADERSMALL = 45
}
