import React from 'react';

import {TextVariantsEnum} from '@sharedInterfaces';

import {Props} from './interfaces';
import {useStyles} from './useStyles';

export const Title: React.FC<Props> = ({
  className,
  children,
  style: customStyles,
  variant
}) => {
  const styles = useStyles();
  return (
    variant === TextVariantsEnum.H1
      ? <h1
          className={className}
        style={{
          ...styles.defaultStyles.title,
          ...styles.variantStyles[variant],
          ...customStyles
        }}
      >{children}</h1>
      : variant === TextVariantsEnum.H2
        ? <h2
            className={className}
            style={{
            ...styles.defaultStyles.title,
            ...styles.variantStyles[variant],
            ...customStyles
          }}>{children}</h2>
        : <h3
            className={className}
            style={{
            ...styles.defaultStyles.title,
            ...styles.variantStyles[variant],
            ...customStyles
          }}>{children}</h3>
  );
};
