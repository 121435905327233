import {FontSizesType} from './interfaces';

const fontSizes: FontSizesType = {
  h: 72,
  l: 32,
  m: 18,
  s: 10,
  xl: 40,
  xm: 24,
  xs: 14,
  xxl: 56
};

enum FontFamily {
  PRIMARY = 'Rowdies'
}
export const fonts = {
  FontFamily,
  fontSizes
};
