import React from 'react';

import {Background, CTNLogo, Title} from '@components';
import {TextVariantsEnum} from '@sharedInterfaces';
import {useTheme} from '@theme';

import {Props} from './interfaces';
import {useStyles} from './useStyles';

import './HomePage.css';
import {useScreenSizes} from '@modules';

export const HomePage: React.FC<Props> = () => {
  const theme = useTheme();
  const {isDesktop} = useScreenSizes();
  const styles = useStyles(theme);

  return (
    <div style={styles.container}>
      <Background />
      <div style={styles.contentContainer}>
        <CTNLogo />
        {isDesktop && (
          <div style={styles.belowLogoContent}>
            <div style={styles.halfDiv}>
              <Title
                style={styles.leftTitle}
                variant={TextVariantsEnum.H3}>A new way to</Title>
            </div>
            <div style={styles.halfDiv}>
              <Title
                style={styles.readTitle}
                variant={TextVariantsEnum.H3}>Read</Title>
              <Title
                style={styles.publishTitle}
                variant={TextVariantsEnum.H3}>Publish</Title>
              <Title
                style={styles.supportTitle}
                variant={TextVariantsEnum.H3}>Support</Title>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
