import {BorderRadiusType, BreakpointsType, IconSizesTypes, SpacingsType} from './interfaces';

const borderRadius: BorderRadiusType = {
  default: 16,
  medium: 8,
  round: 9999999,
  small: 5
};

const breakpoints: BreakpointsType = {
  desktop: 1440,
  mobile: 768,
  tablet: 1024
};

const iconSize: IconSizesTypes = {
  l: 32,
  m: 24,
  s: 16
};

const spacings: SpacingsType = {
  l: 32,
  m: 16,
  s: 8,
  xl: 40,
  xm: 24,
  xs: 4,
  xxl: 56,
  xxs: 2
};

export const metrics = {
  borderRadius,
  breakpoints,
  iconSize,
  spacings
};
