import React, {Children, useState} from 'react';

import {Title} from '@components';
import {useScreenSizes} from '@modules';
import {TextVariantsEnum} from '@sharedInterfaces';

import {Props} from './interfaces';
import {useStyles} from './useStyles';

export const CTNLogo: React.FC<Props> = ({children}) => {
  const styles = useStyles();
  const {isMobile, isTablet, isDesktop} = useScreenSizes();
  const titleSizeVariant =
  isMobile ? TextVariantsEnum.H4 :
    isTablet ? TextVariantsEnum.H3 :
      TextVariantsEnum.H1;
  return (
    <div
      className="CTNLogo"
      style={styles.container}
    >
      {children || (
        <>
          <Title
            style={styles.cryp}
            variant={titleSizeVariant}>CRYP</Title>
          <Title
            style={styles.toon}
            variant={titleSizeVariant}>TOON</Title>
        </>
      )}
    </div>
  );
};
